.list-container {
  background-color: rgb(208, 255, 239);
  overflow: auto;
}

.item {
  text-align: center;
  background-color: burlywood;
}

.item:nth-of-type(2n) {
  background-color: cadetblue;
}

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListItemEven {
  background-color: #f8f8f0;
}